import React from 'react'
import styled from 'styled-components'
import Box from './Box'
import {FullInput} from '../FormFields'

function VaardighedenBox({register}) {
  return <Box title="Vaardigheden">
    <Content>
      <ToolTip>- die ik aan mijn ouders gaf -</ToolTip>
      <Title>mama</Title>
      <Paragraph>misselijkheid, zwangerschapsdementie, nachtelijke escapades, vermoeidheid</Paragraph>
      <Title>Papa</Title>
      <Paragraph>mama masseren, huishoudelijke taken</Paragraph>
      <Title>Welke vaardigheden zal ik van hen krijgen?</Title>
      <FullInput name="baby.skills" ref={register({required: false})}/>
    </Content>
  </Box>
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h4`
`

const Paragraph = styled.p`
  margin-bottom: 20px;
  text-align: center;
`

const ToolTip = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
`

export default VaardighedenBox