import React from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle } from 'styled-components'
import App from './App'

const GlobalStyle = createGlobalStyle`
  @font-face {font-family: "adaywithoutsun";
    src: url("/fonts/a_day_without_sun.eot"); /* IE9*/
    src: url("/fonts/a_day_without_sun.woff2") format("woff2"), /* chrome firefox */
    url("/fonts/a_day_without_sun.woff") format("woff"), /* chrome firefox */
    url("/fonts/a_day_without_sun.ttf") format("truetype") /* chrome firefox opera Safari, Android, iOS 4.2+*/
  }
  
  * {
    margin: 0;
    padding: 0;
  }

  @-webkit-keyframes autofill {
    0%,100% {
      color: #666;
      background: transparent;
    }
  }
  
  .react-time-picker {
    .react-time-picker__wrapper {
      border: 1px solid #f29491;
      //border-color: red !important;
    }
    
    .react-time-picker__clear-button {
      color: #f29491;
    }
    
    .react-time-picker__clear-button__icon {
      stroke: #f29491;
    }
    
    .react-time-picker__inputGroup__input {
      color: #f29491;
    }
    
    .react-time-picker__inputGroup__divider {
      color: #f29491;
    }
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    transition: color 5000s ease-in-out 0s, background-color 5000s ease-in-out 0s;
  }

  amplify-authenticator {
    --container-height: auto;
  }
  
body, html {
  height: 100%;
  background: #b9cdba;
  color: #083c25;
font-family: 'Open Sans', sans-serif;
  letter-spacing: 1px;
}
`

ReactDOM.render(
  <React.StrictMode>
    <App />
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root')
)
