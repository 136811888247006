import React from 'react'
import styled from 'styled-components'
import Box from './Box'

function PersonaliaBox() {
  return <Box>
    <Paragraph>Geachte familie, vrienden en collega’s</Paragraph>
    <Paragraph>Graag stel ik mij kandidaat om het eerste nageslacht
      te worden van Delfien Floré en Rob De Langhe.</Paragraph>
    <Paragraph>Hier alvast een korte voorstelling van mezelf. Ik ben
      Baby Bella en heb reeds enkele maanden ervaring
      opgedaan in mijn mama’s buik. Na overleg met mijn
      dokter kan ik u melden dat ik een meisje zal zijn. Zij
      bevestigde me ook dat zowel mijn mama en ikzelf in
      een ronde en gezonde toestand verkeren.</Paragraph>
    <Paragraph>Rond 7 maart 2021 zal ik beschikbaar kunnen zijn om
      deze functie op mij te nemen. Ik ga graag het engagement aan om hen in een langdurige samenwerking
      het leven lastiger, en toch liefdevoller, te maken.</Paragraph>
    <Paragraph>Hierbij vraag ik uw verdere input om mijn kandidatuur
      te vervolledigen. Alvast bedankt voor uw tijd, ik hoop u
      in maart persoonlijk te mogen ontmoeten.</Paragraph>
    <Paragraph>Met vriendelijk groet<br />
      Baby Bella</Paragraph>
  </Box>
}

const Paragraph = styled.p`
  font-weight: normal;
  margin-bottom: 20px;
`

export default PersonaliaBox