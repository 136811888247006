import React from 'react'
import styled from 'styled-components'

function Box({title, children}) {
  return <Wrapper>
    {title && <Title>{title}</Title>}
    <Content>{children}</Content>
  </Wrapper>
}

const Wrapper = styled.div`
  border: 1px solid black;
  padding: 20px;
  margin-bottom: 50px;
`

const Title = styled.div`
  margin: -30px auto 0 auto;
  padding: 0 20px;
  width: max-content;
  text-transform: uppercase;
  background: #b9cdba;
  font-weight: bold;
`

const Content = styled.div`
`

export default Box