/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBabyPrediction = /* GraphQL */ `
  mutation CreateBabyPrediction(
    $input: CreateBabyPredictionInput!
    $condition: ModelBabyPredictionConditionInput
  ) {
    createBabyPrediction(input: $input, condition: $condition) {
      id
      baby {
        name
        date
        time
        weight
        height
        skills
        freetime
      }
      user {
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBabyPrediction = /* GraphQL */ `
  mutation UpdateBabyPrediction(
    $input: UpdateBabyPredictionInput!
    $condition: ModelBabyPredictionConditionInput
  ) {
    updateBabyPrediction(input: $input, condition: $condition) {
      id
      baby {
        name
        date
        time
        weight
        height
        skills
        freetime
      }
      user {
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBabyPrediction = /* GraphQL */ `
  mutation DeleteBabyPrediction(
    $input: DeleteBabyPredictionInput!
    $condition: ModelBabyPredictionConditionInput
  ) {
    deleteBabyPrediction(input: $input, condition: $condition) {
      id
      baby {
        name
        date
        time
        weight
        height
        skills
        freetime
      }
      user {
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;
