import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {API, graphqlOperation} from 'aws-amplify'
import {listBabyPredictions} from '../graphql/queries'
import {deleteBabyPrediction} from '../graphql/mutations'


const Item = styled.li`
  font-size: 20px;
  display: flex;
  list-style: none;
  margin-bottom: 10px;
  padding-bottom: 10px;
  
  &:not(:last-child) {
    border-bottom: 1px solid #083c25;
  }
`
const SubItem = styled.span`
  display: flex;
`
const ItemTitle = styled.span`
  display: inline-block;
  font-weight: bold;
  width: 200px;
  margin-right: 10px;
`
const ItemContentLine = styled.span`
  display: flex;
  min-width: 300px;
`
const ItemContentTitle = styled.span`
  width: 100px;
  font-weight: bold;
  margin-right: 10px;
`
const ItemContent = styled.span`
  margin-right: 20px;
`
const ItemContentBody = styled.span`
  max-width: 500px;
`

const Actions = styled.span`
`

const Input = styled.span`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
const Delete = styled.button`
  padding: 5px;
`

function PredictionsList({items, refresh}) {
  function removeItem(id) {
    API.graphql(graphqlOperation(deleteBabyPrediction, {input: {id}}))
      .then(refresh)
  }

  const sortedItems = items.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })

  return (
      <ul>
        {sortedItems.map((item) => {
          return (<Item key={item.id}>
            <Input>
              <SubItem>
                <ItemTitle>Deelnemer</ItemTitle>
                <ItemContent><a href={`mailto:${item.user.email}`}>{item.user.name}</a></ItemContent>
              </SubItem>
              <SubItem>
                <ItemTitle>Baby voorspelling</ItemTitle>
                <ItemContent>
                  <ItemContentLine><ItemContentTitle>Naam</ItemContentTitle><ItemContentBody>{item.baby.name}</ItemContentBody></ItemContentLine>
                  <ItemContentLine><ItemContentTitle>Datum</ItemContentTitle><ItemContentBody>{item.baby.date}</ItemContentBody></ItemContentLine>
                  <ItemContentLine><ItemContentTitle>Tijdstip</ItemContentTitle><ItemContentBody>{item.baby.time}</ItemContentBody></ItemContentLine>
                  <ItemContentLine><ItemContentTitle>Gewicht</ItemContentTitle><ItemContentBody>{item.baby.weight}</ItemContentBody></ItemContentLine>
                  <ItemContentLine><ItemContentTitle>Lengte</ItemContentTitle><ItemContentBody>{item.baby.height}</ItemContentBody></ItemContentLine>
                  {item.baby.skills ? <ItemContentLine><ItemContentTitle>Skills</ItemContentTitle><ItemContentBody>{item.baby.skills}</ItemContentBody></ItemContentLine> : null}
                  {item.baby.freetime ? <ItemContentLine><ItemContentTitle>Freetime</ItemContentTitle><ItemContentBody>{item.baby.freetime.join(', ')}</ItemContentBody></ItemContentLine> : null}
                </ItemContent>
              </SubItem>
            </Input>
            <Actions>
              <Delete onClick={() => removeItem(item.id)}>Verwijder</Delete>
            </Actions>
          </Item>)
        })}
      </ul>
  )
}

function Admin() {
  const [response, setResponse] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [fetchCount, setFetchCount] = useState(0)

  function refresh() {
    setFetchCount(fetchCount + 1)
  }

  useEffect(() => {
    setLoading(true)
    API.graphql(graphqlOperation(listBabyPredictions))
      .then((result) => {
        setResponse(result.data.listBabyPredictions.items)
        setLoading(false)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        setError(err)
        setLoading(false)
      })
  }, [fetchCount])
  return (
    <Content>
      {
        loading ? <div>Loading...</div> :
          response ? <PredictionsList items={response} refresh={refresh}/> :
            error ? <div>ERREUR</div> : null}
    </Content>
  )
}

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 15px;
    font-size: 30px;
  }
`

export default Admin;
