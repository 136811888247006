import React from 'react'
import styled from 'styled-components'

function Header() {
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>Baby Bella</Title>
        <SubTitle>CURRICULUM VITAE</SubTitle>
      </TitleWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  margin: 30px auto;
  font-family: 'Abril Fatface', 'Open Sans', sans-serif;
`

const TitleWrapper = styled.header`
  width: auto;
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  font-weight: normal;
  font-size: 100px;
  letter-spacing: 3px;

  @media (max-width: 1000px) {
    font-size: 60px;
  }

  @media (max-width: 500px) {
    font-size: 50px;
  }
`

const SubTitle = styled.h2`
  margin-top: -20px;
  margin-right: 40px;
  font-weight: normal;
  font-size: 23px;
  letter-spacing: 0;
  align-self: flex-end;
  font-family: Roboto, 'Segoe UI', Tahoma, sans-serif;
  
  @media (max-width: 1000px) {
    font-size: 18px;
    margin-right: 0;
    margin-top: -12px;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }
`

export default Header
