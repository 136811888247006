/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:a1301adf-f8a5-4e22-95be-019af599b625",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_ieZYGibJX",
    "aws_user_pools_web_client_id": "2bjpi3kg4d3u9aaqavecnif2rv",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://log7ng6jwne5ppc35xvpdhurg4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uvdzaldhbfgbnmrzzauij2mazy"
};


export default awsmobile;
