import React, { useState } from 'react'
import styled from 'styled-components'
import { API, graphqlOperation } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { createBabyPrediction } from '../graphql/mutations'
import Input from './Input'

function Result() {
  return (
    <ConfirmMessage>
      Proficiat, je maakt nu kans op een Bella-cadeau. Dank je wel voor je deelname en hopelijk ontmoeten we elkaar snel.
    </ConfirmMessage>
  )
}

function Error() {
  return (
    <ErrorMessage>
      Er was een foutje, probeer het later nog eens.
    </ErrorMessage>
  )
}

function InputForm() {
  const [response, setResponse] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const {register, handleSubmit} = useForm()

  const formSubmit = (form) => {
    if (loading) {
      return
    }
    setResponse(undefined)
    setError(undefined)
    setLoading(true)

    const input = {
      ...form,
    }

    API.graphql(graphqlOperation(createBabyPrediction, { input }))
      .then((result) => {
        setResponse(result)
        setLoading(false)
      })
      .catch((err) => {
        setError(err)
        // eslint-disable-next-line no-console
        console.error(err)
        setLoading(false)
      })
  }

  return (
    <Form onSubmit={handleSubmit(formSubmit)}>
      {response ? <Result /> : <>
      <Input loading={loading} register={register} />
      <SaveButton $loading={loading}>CV indienen</SaveButton>
      </>}
      {error ? <Error /> : null}
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`

const ConfirmMessage = styled.div`
  font-size: 30px;
`

const ErrorMessage = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 30px;
`

const SaveButton = styled.button`
  outline: none;
  box-shadow: none;
  border-radius: 3px;
  border: 2px solid #083c25;
  background-color: ${props => props.$loading ? '#083c25' : '#b9cdba'};
  color: ${props => props.$loading ? '#b9cdba' : '#083c25'};
  -webkit-appearance: none;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin: 20px auto 0 auto;
  width: 300px;
  padding: 10px;
  
  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: #083c25;
    color: #b9cdba;
  }

  @media (max-width: 1000px) {
    width: 100%;
    margin: 0;
  }
`

export default InputForm
