import styled from 'styled-components'
import React from 'react'
import Amplify from 'aws-amplify'
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import awsconfig from '../aws-exports'
import Admin from './Admin'

Amplify.configure(awsconfig);

const AuthWrapper = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <Wrapper>
      <Admin />
      <AmplifySignOut buttonText="Uitloggen"/>
    </Wrapper>
  ) : (
    <AmplifyAuthenticator
      container-height="auto">
      <span slot="sign-up" />
      <AmplifySignIn
        slot="sign-in"
        hideSignUp="true"
        usernameAlias="email"
        headerText="Welkom"
        submitButtonText="Inloggen"
        formFields={[
          {
            type: 'email',
            label: 'Email adres',
            placeholder: 'Vul je email adres in',
            required: true,
          },
          {
            type: 'password',
            label: 'Wachtwoord',
            placeholder: 'Vul je wachtwoord in',
            required: true,
          }
        ]}
      />
    </AmplifyAuthenticator>
  );
}

const Wrapper = styled.div`
  margin-bottom: 20px;
`

export default AuthWrapper;