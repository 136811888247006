import React, { useState } from 'react'
import styled from 'styled-components'
import Box from './Box'

function VrijeTijdBox({register}) {
  return <Box title="Vrij Tijd">
    <Content>
      <ToolTip>- maak een keuze -</ToolTip>
      <BoxList>
        <Item name="baby.freetime" value="gamen" register={register}><div>gamen</div></Item>
        <Item name="baby.freetime" value="pottendraaien" register={register}><div>potten-</div><div>draaien</div></Item>
        <Item name="baby.freetime" value="boksen" register={register}><div>boksen</div></Item>
        <Item name="baby.freetime" value="voetbal" register={register}><div>voetbal</div></Item>
      </BoxList>
    </Content>
  </Box>
}

function Item({name, value, children, register}) {
  const [checked, setChecked] = useState(false)

  function toggle() {
    setChecked(!checked)
  }

  return <Checkbox onClick={toggle} good={checked}>
    {children}
    <HiddenCheckbox name={name} type="checkbox" defaultChecked={checked} value={value} ref={register({required: false})}/>
  </Checkbox>
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ToolTip = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
`

const BoxList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 800px) {
    width: 100%;
  }
`

const Checkbox = styled.div`
  border: 1px solid ${(props) => props.good === true ? '#f29491' : 'grey'};
  color: #f29491;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 800px) {
    height: 70px;
    width: 70px;
    border-radius: 35px;
  }

  @media (max-width: 400px) {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    font-size: 14px;
  }
`

const HiddenCheckbox = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
`
export default VrijeTijdBox