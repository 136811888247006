import React from 'react'
import styled from 'styled-components'
import Box from './Box'
import {MediumInput} from '../FormFields'

function PersonaliaBox({register}) {
  return <Box title="Personalia">
    <Row>
      <Label>naam</Label>
      <div>
        <MediumInput name="baby.name" ref={register({required: true})}/>
        <div>De Langhe</div>
      </div>
    </Row>
    <Row>
      <Label>adres</Label>
      <div>Puigem 28<br />9041 Oostakker</div>
    </Row>
    <Row>
      <Label>geboortedatum</Label>
      <DateWrapper name="baby.date" type="date" ref={register({required: true})} max="2021-03-30" min="2021-02-01"/>
    </Row>
    <Row>
      <Label>gewicht</Label>
      <InputWrapper>
        <ResponsiveMediumInput name="baby.weight" type="number" ref={register({required: true})} min="1"/>
      </InputWrapper>
      <InputInfo>gram</InputInfo>
    </Row>
    <Row>
      <Label>lengte</Label>
      <InputWrapper>
        <ResponsiveMediumInput name="baby.height" type="number" ref={register({required: true})} min="1"/>
      </InputWrapper>
      <InputInfo>cm</InputInfo>
    </Row>
  </Box>
}

const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
  &:first-of-type {
    margin-top: 20px;
  }
  @media (max-width: 400px) {
    flex-wrap: wrap;
  }
`

const Label = styled.div`
  width: 40%;
`

const DateWrapper = styled(MediumInput)`
  @media (max-width: 400px) {
    flex-grow: 1;
  }
`

const InputWrapper = styled.div`
  @media (max-width: 400px) {
    flex-grow: 1;
  }
`

const InputInfo = styled.span`
  margin-left: 5px;
  flex-grow: 0;
`

const ResponsiveMediumInput = styled(MediumInput)`
  @media (max-width: 400px) {
    width: 100%;
  }
`

export default PersonaliaBox