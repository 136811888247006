import styled from 'styled-components'

export const Input = styled.input`
  outline: none;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #f29491;
  background-color: transparent;
  -webkit-appearance: none;
  color: #f29491;
  text-align: center;
  font-size: 16px;
  
  &:-internal-autofill-selected {
    background-color: transparent !important;
  }
`

export const SmallInput = styled(Input)`
  width: 100px;
`

export const MediumInput = styled(Input)`
  width: 150px;
`

export const LargeInput = styled(Input)`
  width: 250px;
`

export const HugeInput = styled(Input)`
  width: 400px;
`

export const FullInput = styled(Input)`
  width: 100%;
`
