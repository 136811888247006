import React from 'react'
import styled from 'styled-components'
import Amplify, {Auth} from 'aws-amplify'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import awsconfig from './aws-exports'
import Header from './components/Header'
import InputForm from './components/InputForm'
import AuthWrapper from './components/AuthWrapper'

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

function App() {
  return (
    <Router>
      <Wrapper>
        <Header />
        <Switch>
          <Route path="/admin">
            <AuthWrapper />
          </Route>
          <Route path="/">
            <InputForm />
          </Route>
        </Switch>
      </Wrapper>
    </Router>
  )
}

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 5%;
`

export default App
