/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBabyPrediction = /* GraphQL */ `
  query GetBabyPrediction($id: ID!) {
    getBabyPrediction(id: $id) {
      id
      baby {
        name
        date
        time
        weight
        height
        skills
        freetime
      }
      user {
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBabyPredictions = /* GraphQL */ `
  query ListBabyPredictions(
    $filter: ModelBabyPredictionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBabyPredictions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baby {
          name
          date
          time
          weight
          height
          skills
          freetime
        }
        user {
          name
          email
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
