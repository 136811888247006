import React from 'react'
import styled from 'styled-components'
import Box from './Box'
import {FullInput} from '../FormFields'

function ContactgegevensBox({register}) {
  return <Box title="Contactgegevens">
    <Content>
      <Paragraph>Laat hier je contactgegevens achter om kans te maken op de grote-baby-bella-prijs!</Paragraph>
      <InputWrapper>
        <Label>Naam</Label><FullInput name="user.name" type="text" ref={register({required: true})}/>
      </InputWrapper>
      <InputWrapper>
        <Label>Email</Label><FullInput name="user.email" type="email" ref={register({required: true})}/>
      </InputWrapper>
    </Content>
  </Box>
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 20px 0 20px;
`

const Paragraph = styled.p`
  margin-bottom: 20px;
`

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
`

const Label = styled.span`
  margin-right: 10px;
`


export default ContactgegevensBox