import React from 'react'
import styled from 'styled-components'
import PersonaliaBox from './boxes/PersonaliaBox'
import IntroBox from './boxes/IntroBox'
import VaardighedenBox from './boxes/VaardighedenBox'
import VrijeTijdBox from './boxes/VrijeTijdBox'
import SchiftingsvraagBox from './boxes/SchiftingsvraagBox'
import ContactgegevensBox from './boxes/ContactgegevensBox'
import useWindowSize from './hooks/useWindowSize'

function Input({register}) {
  const { width } = useWindowSize()

  if (width > 1000) {
    return <Wrapper>
      <Row>
        <IntroBox />
        <VrijeTijdBox register={register}/>
        <SchiftingsvraagBox register={register}/>
      </Row>
      <Row>
        <PersonaliaBox register={register}/>
        <VaardighedenBox register={register}/>
        <ContactgegevensBox register={register}/>
      </Row>
    </Wrapper>
  } 
  return <Wrapper>
    <Row>
      <IntroBox />
      <PersonaliaBox register={register}/>
      <VaardighedenBox register={register}/>
      <VrijeTijdBox register={register}/>
      <SchiftingsvraagBox register={register}/>
      <ContactgegevensBox register={register}/>
    </Row>
  </Wrapper>
}

const Wrapper = styled.div`
  display: flex;
  margin: 0 -20px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`

const Row = styled.div`
  flex-basis: 50%;
  padding: 0 20px;
`


export default Input