import React, {useState} from 'react'
import styled from 'styled-components'
import Box from './Box'
import {Input, SmallInput} from '../FormFields'

function SchiftingsvraagBox({register}) {
  const [time, setTime] = useState(undefined)

  function handleChange({target}) {
    setTime(target.value)
  }
  return <Box title="Schiftingsvraag">
    <Content>
      <Question>Wanneer zal mijn effectief opstartuur zijn?</Question>
      <TimeInput name="baby.time" type="time" value={time} onChange={handleChange} ref={register({required: true})} />
      <Info>a.k.a. uur van geboorte en dit is voor extra punten!</Info>
    </Content>
  </Box>
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Question = styled.p`
  margin-top: 20px;
`

const Info = styled.p`
  margin-top: 20px;
  font-size: 12px;
`

const TimeInput = styled(SmallInput)`
  @media (max-width: 400px) {
    max-width: 100px;
  }
`

export default SchiftingsvraagBox